import { Inject, Injectable } from "@angular/core";
import { PARTNER_PORTAL_SETTINGS } from 'app/partner-portal/settings';
import * as _ from "lodash";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import {
    DivSettingKey,
    ICatalogItemDetailResponse,
    IDetailFile,
    IGetAccountInfoResponse,
    IGetAssociatedTemplatesForTemplateResponse,
    IGetContactUsCategoryResponse,
    IGetContactsResponse,
    IGetDealTablesResponse,
    IGetEntityDataResponse,
    ISetting
} from "../../common/messages";
import {
    ICharacteristic,
    ICharacteristicData,
    IEntityDocGroup,
    IEntitySearchDoc,
    IInitializeWizardResponse,
    IPartnerPortalSettings,
    ISearchResults
} from "../../common/models";
import { PPHttpService } from "../../common/services/pp-http.service";


@Injectable()
export class PartnerPortalService {

    private _baseUrl = "/service-api/partner-portal";
    divSettings$ = new ReplaySubject<ISetting[]>(1);
    divImageUrl$ = new BehaviorSubject<string>(null);
    hexColor1$ = new BehaviorSubject<string>(null);
    hexColor2$ = new BehaviorSubject<string>(null);
    hexColor3$ = new BehaviorSubject<string>(null);
    hexColor4$ = new BehaviorSubject<string>(null);
    defaultLogo = this.settings.logoUrl;
    placeholderHex1 = "#1e003e";
    placeholderHex2 = "#412c80";
    placeholderHex3 = "#7861b8";
    placeholderHex4 = "#efeffd";
    divSettings: ISetting[];
    constructor(private ppHttpService: PPHttpService, @Inject(PARTNER_PORTAL_SETTINGS) private settings: IPartnerPortalSettings) {

    }

    refreshSettings() {
        this.getDivSettingsList().pipe(
            map(result => {
                return Object.keys(result).map(key => result[key]).sort((a, b) => a.key.localeCompare(b.key));
            }),
            tap(settings => {
                this.divSettings$.next(settings);
                this.divSettings = settings;
                var portalLogo = settings.find(x => x.key === DivSettingKey.PortalLogo) === undefined ? this.defaultLogo : settings.find(x => x.key === DivSettingKey.PortalLogo).derivedValue;
                var hexColor1 = settings.find(x => x.key === DivSettingKey.HexColor1) === undefined ? this.placeholderHex1 : settings.find(x => x.key === DivSettingKey.HexColor1).value;
                var hexColor2 = settings.find(x => x.key === DivSettingKey.HexColor2) === undefined ? this.placeholderHex2 : settings.find(x => x.key === DivSettingKey.HexColor2).value;
                var hexColor3 = settings.find(x => x.key === DivSettingKey.HexColor3) === undefined ? this.placeholderHex3 : settings.find(x => x.key === DivSettingKey.HexColor3).value;
                var hexColor4 = settings.find(x => x.key === DivSettingKey.HexColor4) === undefined ? this.placeholderHex4 : settings.find(x => x.key === DivSettingKey.HexColor4).value;

                if (portalLogo !== undefined) {
                    this.divImageUrl$.next(portalLogo);
                }
                if (hexColor1 !== undefined) {
                    this.hexColor1$.next(hexColor1);
                    this.changeTheme(DivSettingKey.HexColor1, hexColor1);
                }
                if (hexColor2 !== undefined) {
                    this.hexColor2$.next(hexColor2);
                    this.changeTheme(DivSettingKey.HexColor2, hexColor2);
                }
                if (hexColor3 !== undefined) {
                    this.hexColor3$.next(hexColor3);
                    this.changeTheme(DivSettingKey.HexColor3, hexColor3);
                }
                if (hexColor4 !== undefined) {
                    this.hexColor4$.next(hexColor4);
                    this.changeTheme(DivSettingKey.HexColor4, hexColor4);
                }
            })).subscribe();
    }

    changeTheme(hexColorKey: string, value: string) {
        switch (hexColorKey) {
            case DivSettingKey.HexColor1: {
                document.documentElement.style.setProperty('--brand-primary-color', value);
                break;
            }
            case DivSettingKey.HexColor2: {
                document.documentElement.style.setProperty('--brand-secondary-color', value);
                break;
            }
            case DivSettingKey.HexColor3: {
                document.documentElement.style.setProperty('--brand-secondary-light-color', value);
                break;
            }
            case DivSettingKey.HexColor4: {
                document.documentElement.style.setProperty('--brand-tertiary-color', value);
                break;
            }
        }
    }

    getAssociatedCatalogItems(keywords: string, templateID: number, start: number, rows: number, statusID: number, sortField?: string) {
        const data = {
            keywords: keywords,
            templateID: templateID,
            start: start,
            rows: rows,
            sortField: sortField,
            statusID: statusID
        };
        return this.ppHttpService.post<{
            documents: IEntitySearchDoc[],
            keyArt: { [key: number]: string },
            templateFacets: { key: string; value: number; }[],
            statusFacets: { key: string; value: number; }[],
            numFound: number
        }>(`${this._baseUrl}/get-associated-catalog-items`, data);
    }

    getCatalogItemDetail(assetID: number, displayTitleField?: string) {
        const data = {
            assetID: assetID,
            displayTitleField: displayTitleField
        };
        return this.ppHttpService.get<ICatalogItemDetailResponse>(`${this._baseUrl}/get-catalog-item-detail`, data);
    }

    getRelatedCatalogItems(assetID: number, start: number, rows: number) {
        const data = {
            assetID: assetID,
            start: start,
            rows: rows
        };
        return this.ppHttpService.post<{
            documents: IEntitySearchDoc[],
            keyArt: { [key: number]: string },
            numFound: number
        }>(`${this._baseUrl}/get-related-catalog-items`, data);
    }

    getNewReleaseCatalogItems(keywords: string, templateID: number, start: number, rows: number, sortField?: string) {
        const data = {
            keywords: keywords,
            templateID: templateID,
            start: start,
            rows: rows,
            sortField: sortField
        };
        return this.ppHttpService.post<{
            documents: IEntitySearchDoc[],
            keyArt: { [key: number]: string },
            templateFacets: { key: string; value: number; }[],
            numFound: number
        }>(`${this._baseUrl}/get-new-release-catalog-items`, data);
    }

    getAccountInfo() {
        return this.ppHttpService.get<IGetAccountInfoResponse>(`${this._baseUrl}/get-account-info`);
    }

    saveAccountInfo(charData: ICharacteristicData[]) {
        return this.ppHttpService.post<void>(`${this._baseUrl}/save-account-info`, charData);
    }

    verifyPasswordResetToken(token: string) {
        return this.ppHttpService.get<{ success: boolean; status: string }>(`${this._baseUrl}/verify-password-reset-token`,
            { token: token });
    }

    claimPasswordReset(divID: number, token: string, password: string) {
        const data = {
            divID: divID,
            token: token,
            password: password,
            clientID: this.settings.clientID
        };
        return this.ppHttpService.post<{ success: boolean; message: string; contactUpdateRequired: boolean; }>(
            `${this._baseUrl}/claim-password-reset`,
            data);
    }

    resetPassword(email: string) {
        var data = {
            email,
            clientID: this.settings.clientID
        };
        return this.ppHttpService.post<void>(`${this._baseUrl}/reset-password`, data);
    }

    getParties(recordID: number, charTypeID: number) {
        return this.ppHttpService.get<IGetContactsResponse>(`${this._baseUrl}/get-parties/${recordID}/${charTypeID}`);
    }

    getAssociatedDeals(keywords: string, templateID: number, start: number, rows: number, statusID: number) {
        const data = {
            keywords: keywords,
            templateID: templateID,
            start,
            rows,
            statusID: statusID
        };
        return this.ppHttpService.post<{
            documents: IEntitySearchDoc[],
            numFound: number,
            templateFacets: { key: string; value: number; }[],
            statusFacets: { key: string; value: number; }[],
        }>(`${this._baseUrl}/get-associated-deals`, data);
    }

    getAssociatedRecords(keywords: string, templateID: number, entityID: string, associatedCharTypeID: number, start: number, rows: number) {
        const data = {
            keywords: keywords,
            templateID: templateID,
            entityID: entityID,
            associatedCharTypeID: associatedCharTypeID,
            start,
            rows
        };
        return this.ppHttpService.post<{
            documents: IEntitySearchDoc[],
            numFound: number,
            templateFacets: { key: string; value: number; }[],
            statusFacets: { key: string; value: number; }[]
        }>(`${this._baseUrl}/get-associated-records`, data);
    }

    getDealDetails(recordID: number) {
        return this.ppHttpService.get<IGetEntityDataResponse>(`${this._baseUrl}/get-deal-details/${recordID}`);
    }

    getFileDetails(recordID: number) {
        return this.ppHttpService.get<IGetEntityDataResponse>(`${this._baseUrl}/get-file-details/${recordID}`)
    }

    getDealTables(recordID: number, templateID: number, start: number, rows: number) {
        return this.ppHttpService.get<IGetDealTablesResponse>(
            `${this._baseUrl}/get-deal-tables/${recordID}/${templateID}/${start}/${rows}`);
    }

    getAssociatedTemplatesForTemplate(charTypeID: number, templateID: number, childCharTypeID: number) {
        const url = `${this._baseUrl}/get-associated-templates-for-template/${charTypeID}/${templateID}/${childCharTypeID}`;
        return this.ppHttpService.get<IGetAssociatedTemplatesForTemplateResponse>(url);
    }

    getSessionInfo() {
        return this.ppHttpService.get<{ hasCatalog: boolean; hasProjects: boolean; hasDeals: boolean; hasAccounting: boolean; hasB2BRole: boolean, hasJobs: boolean, hasInventory: boolean, hasFiles: boolean, showUploads: boolean }>(
            `${this._baseUrl}/get-session-info`);
    }

    getFiles(recordID: number, charTypeID: number) {
        return this.ppHttpService
            .get<IDetailFile[]>(`${this._baseUrl}/get-files/${recordID}/${charTypeID}`);
    }

    getAssociatedAccountingDocs(keywords: string, templateID: number, start: number, rows: number, statusID: number) {
        const data = {
            keywords: keywords,
            templateID: templateID,
            start,
            rows,
            statusID: statusID
        };
        return this.ppHttpService.post<{
            documents: IEntitySearchDoc[],
            numFound: number,
            templateFacets: { key: string; value: number; }[]
            statusFacets: { key: string; value: number; }[]
        }>(`${this._baseUrl}/get-associated-accounting`, data);
    }

    getAccountingAmounts(recordID: number, start: number, rows: number, sortField?: string) {
        let url = `${this._baseUrl}/get-accounting-amounts/${recordID}/${start}/${rows}`;

        if (!_.isEmpty(sortField)) {
            url = `${this._baseUrl}/get-accounting-amounts/${recordID}/${start}/${rows}/${sortField}`;
        }

        return this.ppHttpService.get<ISearchResults>(url);
    }

    getAccountingDetails(recordID: number) {
        return this.ppHttpService.get<IGetEntityDataResponse>(`${this._baseUrl}/get-accounting-details/${recordID}`);
    }

    getAssociatedProjects(keywords: string, templateID: number, start: number, rows: number, statusID: number) {
        const data = {
            keywords: keywords,
            templateID: templateID,
            start,
            rows,
            statusID: statusID
        };
        return this.ppHttpService.post<{
            documents: IEntitySearchDoc[],
            numFound: number,
            templateFacets: { key: string; value: number; }[]
            statusFacets: { key: string; value: number; }[]
        }>(`${this._baseUrl}/get-associated-projects`, data);
    }

    getProjectDetails(recordID: number) {
        return this.ppHttpService.get<IGetEntityDataResponse>(`${this._baseUrl}/get-project-details/${recordID}`);
    }

    getAssociatedJobs(keywords: string, templateID: number, start: number, rows: number) {
        const data = {
            keywords: keywords,
            templateID: templateID,
            start,
            rows
        };
        return this.ppHttpService.post<{
            documents: IEntitySearchDoc[],
            numFound: number,
            templateFacets: { key: string; value: number; }[]
        }>(`${this._baseUrl}/get-associated-jobs`, data);
    }

    getJobDetails(recordID: number) {
        return this.ppHttpService.get<IGetEntityDataResponse>(`${this._baseUrl}/get-job-details/${recordID}`);
    }

    getAssociatedInventories(keywords: string, templateID: number, start: number, rows: number, statusID: number) {
        const data = {
            keywords: keywords,
            templateID: templateID,
            start,
            rows,
            statusID: statusID
        };
        return this.ppHttpService.post<{
            documents: IEntitySearchDoc[],
            numFound: number,
            templateFacets: { key: string; value: number; }[]
            statusFacets: { key: string; value: number; }[]
        }>(`${this._baseUrl}/get-associated-inventories`, data);
    }

    getInventoryDetails(recordID: number) {
        return this.ppHttpService.get<IGetEntityDataResponse>(`${this._baseUrl}/get-inventory-details/${recordID}`);
    }

    getAssociatedFiles(keywords: string, templateID: number, start: number, rows: number, statusID: number) {
        const data = {
            keywords: keywords,
            templateID: templateID,
            start,
            rows,
            statusID: statusID
        };
        return this.ppHttpService.post<{
            documents: IEntityDocGroup[],
            numFound: number,
            templateFacets: { key: string; value: number; }[],
            statusFacets: { key: string; value: number; }[],
            fileURLs: { [key: number]: string },
            associatedDeals: { [key: number]: string },
            sourceFields: { [template: number]: ICharacteristic[] }[]
        }>(`${this._baseUrl}/get-associated-files-grouped`, data);
    }

    getAllAssociatedFiles(keywords: string, templateID: number, start: number, rows: number, characteristicsCount: number, orderBySequence: boolean, statusID: number) {
        const data = {
            keywords: keywords,
            templateID: templateID,
            start,
            rows,
            characteristicsCount,
            orderBySequence,
            statusID: statusID
        };
        return this.ppHttpService.post<{
            documents: IEntitySearchDoc[],
            numFound: number,
            templateFacets: { key: string; value: number; }[],
            statusFacets: { key: string; value: number; }[],
            fileURLs: { [key: number]: string },
            associatedDeals: { [key: number]: string },
            sourceFields: ICharacteristic[]
        }>(`${this._baseUrl}/get-associated-files-all`, data);
    }

    contactUs(category: string, email: string, input: string) {
        var data = {
            category,
            email,
            clientInput: input
        };
        console.log(data);
        return this.ppHttpService.post<void>(`${this._baseUrl}/contact-us`, data);
    }

    getContactUsCategories() {
        return this.ppHttpService.get<IGetContactUsCategoryResponse>(`${this._baseUrl}/contact-us-info`);
    }

    getDivSettingsList() {
        return this.ppHttpService.get<{ [key: string]: ISetting }>(`${this._baseUrl}/list`);
    }

    createImageDivSetting(key: string, location) {
        let data = {
            key: key,
            location
        }
        return this.ppHttpService.post<void>(`${this._baseUrl}/div/image`, data);
    }

    createDivSetting(key: string, value: string) {
        let data = {
            key,
            value
        }
        return this.ppHttpService.post<void>(`${this._baseUrl}/div`, data);
    }

    deleteDivSetting(divSettingId: string) {
        return this.ppHttpService.delete<void>(`${this._baseUrl}/div/${divSettingId}`);
    }

    updateDivSetting(divSettingId: string, key: string, value: string) {
        let data = {
            divSettingId,
            key,
            value
        }
        return this.ppHttpService.put<void>(`${this._baseUrl}/div`, data);
    }

    // Get Records that can be associated to the specified File template
    getAssociableRecords(templateId: number, charTypeId: number, start: number = 0, rows: number = 10) {
        return this.ppHttpService.get<ISearchResults>(`${this._baseUrl}/template/${templateId}/associable-records/${charTypeId}?start=${start}&rows=${rows}`);
    }

    getEmptyFileWizardData(templateID) {
        return this.ppHttpService.get<IInitializeWizardResponse>(`${this._baseUrl}/${templateID}/empty-file-wizard-data`);
    }

}
