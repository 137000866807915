<div class="card" [class.card-h-100]="displaySummarizedView">
  <div class="card-body" [class.text-start]="displaySummarizedView">
    <header>
      <div class="section-head row align-items-center">
        <div class="col-6">
          <h1 *ngIf="includeHeading"><a [routerLink]="linkHelper.list()" data-cy="FilesHeaderLink">Files</a></h1>
        </div>
        <div class="col-6 table__header d-flex justify-content-end">
          <div ngbDropdown class="rl-table-dropdown" placement="bottom-end">
            <button ngbDropdownToggle class="btn btn-icon btn-info hide-caret" data-cy="FilesHamburgerMenu">
              <i class="far fa-bars"></i>
            </button>
            <ul ngbDropdownMenu class="dropdown-menu-right" role="menu">
              <li ngbDropdownItem>
                <a href="javascript:void()" (click)="exportFiles($event)" data-cy="FilesExportItems">Export</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
    <div>
      <div *ngFor="let docGroup of documents; let  i = $index" [class.mb-3]="i!==documents.length" class="scroll-horiz position-relative">
        <h4>
          {{docGroup.documents[0].template}}
        </h4>
        <table class="table rl-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th *ngFor="let field of sourceFields[docGroup.template]" [innerText]="field.label"></th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let document of docGroup.documents">
              <td>
                <span>{{document.recordID}}</span>
              </td>
              <td>
                <span>{{document.title}}</span>
              </td>
              <td *ngFor="let column of sourceFields[docGroup.template]">
                <span *ngIf="column.dataTypeID==12 && document.characteristics[column.tagLabel]"
                      [innerText]="getCleanFileName(document.characteristics[column.tagLabel])"></span>
                <span *ngIf="column.dataTypeID!=12 && document.characteristics[column.tagLabel]"
                      [innerText]="document.characteristics[column.tagLabel]"></span>
              </td>
              <td [innerText]="document.status"></td>
              <td class="p-0">
                <div class="hover-icons text-end">
                  <button *ngIf="canWrite" (click)="openEditDocumentEntity(document.entityID, document, $event)"
                          class="btn btn-sm btn-icon btn-info me-2" data-cy="editDocument">
                    <i class="far fa-pencil"></i>
                  </button>
                  <button (click)="previewFile(document)" class="btn btn-sm btn-icon btn-info me-2" data-cy="previewDocument">
                    <i class="far fa-eye fa-sm"></i>
                  </button>
                  <a href="#" [href]="getFileURL(document.recordID)" class="btn btn-sm btn-icon btn-info me-2"
                     data-cy="downloadDocument"><i class="far fa-download fa-sm"></i></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<pp-modal #editDocumentEntityModal modalWidth="60%">
  <modal-title>{{uploadFileFolderModalTitle}} </modal-title>
  <modal-body>
    <char-data-table #uploadTable [charData]="uploadCharData" [template]="uploadCharMetaDataCollection" [editMode]="true"
                     (tableCharDataChange)="uploadCharData=$event"></char-data-table>
  </modal-body>
  <modal-footer>
    <button type="button" class="btn btn-light" (click)="editDocumentEntityModalClose();" data-cy="cancelButton">Cancel</button>
    <button type="button" class="btn btn-success ms-2" [disabled]="!uploadTable.valid" (click)="saveDocumentEntity(uploadTable.valid);" busyButton
            data-cy="saveButton">
      <i class="fa"></i>Save
    </button>
  </modal-footer>
</pp-modal>
